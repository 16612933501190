<template>
  <Layout>
    <el-form
      ref="form"
      :rules="rules"
      :model="formData"
      label-width="200px"
    >
      <header class="header">
        基本信息
      </header>
      <el-form-item
        label="商品名"
        prop="goodsName"
      >
        <el-input
          v-model="formData.goodsName"
          style="width: 420px"
        />
      </el-form-item>
      <el-form-item
        prop="headerImage"
        label="封面横图(展示在商品首页)"
      >
        <UploadImage
          :limit="1"
          :file-list.sync="coverList"
        />
      </el-form-item>
      <el-form-item
        prop="carouselImage"
        label="产品轮播图"
      >
        <UploadImage
          :limit="6"
          :file-list.sync="carouselList"
        />
      </el-form-item>
      <el-form-item
        prop="goodsDescArray"
        label="商品描述"
      >
        <template>
          <descriptionList :data.sync="formData.goodsDescArray" />
        </template>
      </el-form-item>

      <header class="header">
        <span>价格信息 <sub>(最多两位小数)</sub></span>
      </header>
      <el-form-item
        label="产品标价"
        prop="goodsPrice"
      >
        <el-input
          v-model="formData.goodsPrice"
          clearable
          style="width: 420px"
        >
          <template slot="prepend">
            <i class="fa fa-dollar" />
          </template>
        </el-input>
      </el-form-item>

      <header
        class="header"
        style="width:880px;"
      >
        不同地区运费
        <el-button @click="handleAddNation">
          添加国家/地区
        </el-button>
      </header>
      <el-table
        style="margin-left: 200px; margin-bottom: 80px; width: 800px;"
        :data="nationList"
        border
      >
        <el-table-column
          label="国家/地区"
          prop="nation"
        />
        <el-table-column
          label="运费"
          prop="freight"
        />
        <el-table-column label="操作">
          <template #default="{row}">
            <el-button
              type="primary"
              plain
              @click="handleEditRow(row)"
            >
              <i class="el-icon-edit" />
            </el-button>

            <el-button @click="handleDeleteRow(row)">
              <i class="el-icon-delete" />
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-form-item>
        <el-button
          style="width: 150px;"
          type="primary"
          plain
          @click="handleConfirm"
        >
          保存
        </el-button>
        <el-button
          style="width: 150px;"
          plain
          @click="handleCancel"
        >
          取消
        </el-button>
      </el-form-item>
    </el-form>

    <el-dialog
      :title="`编辑运费(${tempRow.nation})`"
      :visible.sync="editDialogVisible"
      width="500px"
      @submit.native.prevent
    >
      <el-form
        ref="formEdit"
        :model="tempRow"
        :rules="rulesNation"
      >
        <el-form-item prop="freight">
          <el-input
            v-model="tempRow.freight"
            placeholder="输入运费"
          />
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="formLoading"
          @click="updateTempRow"
        >确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="添加国家/地区"
      :visible.sync="nationDialogVisible"
      width="500px"
    >
      <el-form
        ref="formNation"
        :inline="true"
        :model="nationFormData"
        @submit.native.prevent
      >
        <el-form-item>
          <el-input
            v-model="nationFormData.nation"
            clearable
            placeholder="搜索国家/地区"
            @change="nationFormData.nation = nationFormData.nation.trim()"
            @keyup.enter.native="handleSearchNation"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearchNation"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
      <ul class="searchList">
        <li
          v-for="(item, index) in searchList"
          :key="index"
          @click="doAddNation(item)"
        >
          {{ item.nation }}
        </li>
      </ul>
    </el-dialog>
  </Layout>
</template>

<script>
import { cloneDeep, isEmpty } from 'lodash'
import {
  getUserNationStatistics,
  addMallv2PhysicalGoods,
  getMallv2PhysicalGoodsDetail,
  updateMallv2PhysicalGoods
} from '@/api/electronicEntity.js'
import descriptionList from './components/descriptionList.vue'

export default {
  components: { descriptionList },
  data () {
    const numberCheck = (rule, value, callback) => {
      if (isNaN(value)) {
        callback(new Error('此项必须为数字'))
      } else {
        callback()
      }
    }

    const RANGE_CHECK = (rule, value, callback) => {
      if (value <= 0) {
        callback(new Error('此项需大于0'))
      } else {
        callback()
      }
    }

    const DECIMAL_CHECK = (rule, value, callback) => {
      const decimalLength = (value.toString().split('.')[1] || '').length || 0
      if (decimalLength > 2) {
        callback(new Error('最多两位小数'))
      } else {
        callback()
      }
    }

    const coverCheck = (rule, value, callback) => {
      if (this.coverList.length) {
        return callback()
      } else {
        callback(new Error('封面图必填'))
      }
    }

    const carouselCheck = (rule, value, callback) => {
      if (this.carouselList.length) {
        return callback()
      } else {
        callback(new Error('轮播图必填'))
      }
    }
    /* eslint-disable */
    const descriptionCheck = (rule, value, callback) => {
      if (value && value.length) {
        if (value.filter(item => {
          return !isEmpty(item)
        }).length) {
          callback()
        } else {
          callback('请填写相关字段')
        }
      } else {
        callback(new Error('商品描述必填'))
      }
    }

    return {
      coverList: [],
      carouselList: [],
      descriptionList: [],
      formData: {},
      nationList: [],
      searchList: [],
      rules: {
        goodsName: [
          { required: true, message: '商品名必填' }
        ],
        headerImage: [{ required: true, validator: coverCheck }],
        carouselImage: [{ required: true, validator: carouselCheck }],
        goodsPrice: [
          { required: true, message: '产品标价必填' },
          { validator: numberCheck },
          { validator: RANGE_CHECK },
          { validator: DECIMAL_CHECK }
        ],
        goodsDescArray: [
          { required: true, validator: descriptionCheck }
        ]
      },
      editDialogVisible: false,
      tempRow: {},
      originRow: {},
      formDataNation: {},
      rulesNation: {
        freight: [
          { required: true, message: '运费必填' },
          { validator: numberCheck },
          { validator: RANGE_CHECK },
          { validator: DECIMAL_CHECK }
        ]
      },
      formLoading: false,

      nationDialogVisible: false,
      nationFormData: {}
    }
  },
  created () {
    this.handleSearchNation()
    this.isEdit = this.$route.name === 'goods-item-edit'
    if (this.isEdit) {
      this.queryData()
    }
  },
  methods: {
    queryData () {
      getMallv2PhysicalGoodsDetail({
        id: this.$route.query.id
      })
        .then(res => {
          if (res.code === 200) {
            const data = res.data
            this.formData = data
            this.coverList = [{
              url: data.headerImage
            }]
            this.carouselList = data.carouselImage.map(url => {
              return {
                url: url
              }
            })
            this.nationList = data.freightPriceArray.map(item => {
              const { nation, freightPrice: freight, countryCode } = item
              return { nation, freight, countryCode }
            })
          }
        })
    },
    handleConfirm () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (!this.isEdit) {
            // 创建
            this.addMallv2PhysicalGoods()
          } else {
            // 更新
            this.updateMallv2PhysicalGoods()
          }
        }
      })
    },
    formateParams () {
      const formData = cloneDeep(this.formData)

      formData.headerImage = this.coverList[0].url

      formData.carouselImage = this.carouselList.map(item => {
        return item.url
      })

      formData.freightPriceArray = this.nationList.map(item => {
        const { nation, freight: freightPrice, countryCode } = item
        return { nation, freightPrice, countryCode }
      })
      return formData
    },
    addMallv2PhysicalGoods () {
      const formData = this.formateParams()
      addMallv2PhysicalGoods(formData)
        .then(res => {
          if (res.code === 200) {
            this.$message.success('商品添加成功')
            this.$router.go(-1)
          }
        })
    },
    updateMallv2PhysicalGoods () {
      const formData = this.formateParams()
      updateMallv2PhysicalGoods(formData)
        .then(res => {
          if (res.code === 200) {
            this.$message.success('商品更新成功')
            this.$router.go(-1)
          }
        })
    },
    handleCancel () {
      this.$router.go(-1)
    },
    handleEditRow (row) {
      this.tempRow = cloneDeep(row)
      this.originRow = row
      this.editDialogVisible = true
    },
    handleDeleteRow (row) {
      this.$confirm(`确定删除${row.nation}吗?`, '提示')
        .then(() => {
          this.nationList = this.nationList.filter(item => {
            return item.nation !== row.nation
          })
        })
    },
    updateTempRow () {
      this.$refs.formEdit.validate(valid => {
        if (valid) {
          this.originRow.freight = this.tempRow.freight
          this.editDialogVisible = false
        }
      })
    },
    handleAddNation () {
      this.nationDialogVisible = true
    },
    handleSearchNation () {
      getUserNationStatistics({
        searchWord: this.nationFormData.nation,
        pageNum: 1,
        pageSize: 20
      }).then(res => {
        if (res.code === 200) {
          this.searchList = res.data.list
        }
      })
    },
    doAddNation (item) {
      if (this.nationList.find(it => {
        return it.nation === item.nation
      })) {
        this.$message.warning('该国家/地区已存在')
        return false
      }
      this.$set(item, 'freight', 5)
      this.nationList.unshift(item)
      this.nationDialogVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
    header {
      margin: 40px 0 20px 100px;
      padding-left: 20px;
      font-size: 18px;
      display: flex;
      justify-content: space-between;
    }
    .searchList {
      list-style: none;
      padding-left: 0;
      max-height: 500px;
      overflow: auto;
      border-top: 1px solid #eee;
      li {
        border-bottom: 1px solid #eee;
        padding: 20px 0;
        cursor: pointer;
      }
    }
</style>
