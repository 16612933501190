<template>
  <section class="GoodsDescriptionList">
    <div
      v-for="(item, index) in data"
      :key="index"
      class="rows"
    >
      <div class="key">
        <el-input
          v-model="item.title"
          placeholder="Title"
        />
      </div>
      <div class="description">
        <el-input
          v-model="item.docs"
          placeholder="Description"
        />
      </div>
      <div class="delete">
        <el-button
          type="text"
          class="delete-button"
          @click="deleteRows(index)"
        >
          删除本行
        </el-button>
      </div>
    </div>
    <div
      v-if="!data.length"
      class="empty"
    >
      暂无数据
    </div>
    <div class="addRows">
      <el-button
        class="add-button"
        type="text"
        @click="addRows"
      >
        添加一行
      </el-button>
    </div>
  </section>
</template>

<script>
import { cloneDeep } from 'lodash'
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    deleteRows (index) {
      const list = cloneDeep(this.data)
      list.splice(index, 1)
      this.$emit('update:data', list)
    },
    addRows () {
      this.$emit('update:data', this.data.concat([{}]))
    }
  }
}
</script>

<style lang="less">
  .GoodsDescriptionList {
    width: 800px;
    .rows {
      display: flex;
      width: 800px;
      .key {
        flex: 1;
      }
      .description {
        flex: 1;
        text-align: right;
        margin-left: 20px;
      }
      .delete {
        text-align: center;
        width: 100px;
        display: flex;
        align-items: flex-end;
        .delete-button {
          padding: 0;
          margin-left: 20px;
        }
      }
      .el-input {
        display: inline-block;
        height: 47px;
        input {
          background: white;
          border: 0;
          border-bottom: 1px solid @primary;
          -webkit-appearance: none;
          border-radius: 0;
          color: #333333;
          height: 47px;
          caret-color: #333333;
          &:-webkit-autofill {
            box-shadow: 0 0 0 1000px white inset !important;
            -webkit-text-fill-color: #333333 !important;
          }
        }
    }
    }
    .empty {
      width: 700px;
      text-align: center;
      font-size: 14px;
      margin-top: 30px;
      color: #aaa;
    }
    .addRows {
      width: 700px;
      text-align: center;
      .add-button {
        width: 300px;
        margin-top: 20px;
      }
    }
  }
</style>
